<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5 mb-3">タグの設定</h1>
        <banner-hint>
          タグはキャストの特徴を表すために使われ、HPのキャストプロフィール内に表示されます。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        v-if="tags.length"
      >
        <v-card
          class="py-5 px-10"
          elevation="1"
        >
          <v-row no-gutters>
            <v-col cols="12" md="6" lg="4"
              class="mb-5 pt-3"
              v-for="(tag, index) in tags"
              :key="tag.tag_id"
            >
              <!-- 入力一列 -->
              <v-form :ref="'form-tag-' + tag.tag_id">
                <v-row no-gutters>
                  <v-col cols="8" md="6"
                    class="px-1"
                  >
                    <v-text-field
                      v-model.trim="tag.tag_name"
                      required
                      label="タグ名"
                      counter="8"
                      :rules="[valiRules.required, valiRules.max8]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" md="6"
                    class="d-flex justify-start align-center pt-0 pl-2"
                    :class="$vuetify.breakpoint.mdAndUp ? 'justify-start' : 'justify-end'"
                  >
                    <v-btn
                      v-if="tag.create"
                      depressed small
                      color="accent"
                      @click="createRow(tag)"
                    >登録</v-btn>
                    <v-btn
                      v-else
                      depressed small
                      color="primary"
                      @click="updateRow(tag)"
                    >更新</v-btn>
                    <v-btn
                      class="ml-2"
                      text
                      depressed small
                      color="primary"
                      @click="deleteRow(index)"
                    >削除</v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <!-- 要素追加ボタン -->
      <v-col cols="12"
        class="mb-5"
      >
        <v-btn
          depressed
          color="primary"
          @click="addBlankRow()"
        >タグを追加</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card v-if="!tags.length" flat>
          <v-card-text>タグが登録されていません。</v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { SITE_API_ENDPOINT } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue";

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      maxId: 0,
      tags: [],
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
      siteApi: new ApiTool(SITE_API_ENDPOINT + '/', this.shopData),
    };
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderLoading

      await Promise.all([
        this.getTags(),
      ])

      this.loading = false
    },

    //入力列追加
    addBlankRow() {
      if (this.tags.length >= 30) {
        this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoMaxedupRows, open: true}}
        return
      }

      this.tags.push({
        tag_id: ++this.maxId,
        tag_name: '',
        create: true
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    // API req: GET
    //**************************************************
    getTags() {
      return this.siteApi.getReqSitePublic('cast-tag/').then( results => {
        if (!results || !results.length) return

        //id最大値の取得
        this.maxId = Math.max(...results.map( row => parseInt(row.tag_id) ))

        this.tags = results
      })
    },

    //**************************************************
    //新規登録
    //**************************************************
    createRow(tag) {
      if (!this.$refs['form-tag-' + tag.tag_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = $literals.MESSAGE.loaderInTarnsaction

      const formData = new FormData();
      formData.append("tag_name", tag.tag_name);

      this.adminApi.apiReqWithData('POST', 'create/cast-tag/', formData).then( response => {
        tag.tag_id = response.tag_id
        tag.create = false

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successCreateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },

    //**************************************************
    //更新
    //**************************************************
    updateRow(tag) {
      if (!this.$refs['form-tag-' + tag.tag_id][0].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      const apiPath = 'update/cast-tag/' + tag.tag_id
      const formData = new FormData();
      formData.append("tag_name", tag.tag_name);

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then( response => {
        if (response.NoRowsAffected) {
          this.snackbar = {...{color:'info', message: $literals.MESSAGE.infoNoRowsAffected, open: true}}
        } else {
          this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
        }
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },

    //**************************************************
    //削除
    //**************************************************
    deleteRow(index) {
      if (this.tags[index].create) {
        this.tags.splice(index, 1)
        return
      }

      const apiPath = 'delete/cast-tag/' + this.tags[index].tag_id

      this.adminApi.apiReqWithData('DELETE', apiPath).then(() => {
        this.tags.splice(index, 1)

        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successDeleteSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    },
  }
}
</script>

<style scoped>
>>> .v-input__slot {
  margin-bottom: 4px;
}
</style>
